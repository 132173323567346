.privacy {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 16px;
}
.privacy h2 {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size:16px;
    text-transform: normal;
    font-weight:normal;
    text-align:center;
}
.privacy h3 {
    margin-bottom: 30px;
    text-align:center;
}

.privacy h4 {
    margin-bottom: 20px;
    margin-top: 20px;
    text-align:center;
}

.privacy div {
    max-width: 1000px;
    margin: 120px auto 20px;
    padding: 30px 30px;

    overflow: hidden;
    border-radius: 12px;
    background-color: white;
}

.privacy p {
    max-height: 480px;
   padding:6px;
    overflow-y: auto;
}

.privacy i{
    margin-right:5px;
}