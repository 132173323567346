.welcome_placeholder {
    padding: 30px 20px;
    line-height: 1.5;
    font-weight: 700;
    color: white;
}

.welcome_placeholder > div {
    position: absolute;
    bottom: 0;
    left: 5px;
}

.welcome_placeholder > div > div:nth-of-type(2),
.welcome_placeholder > div > div:nth-of-type(3),
.welcome_placeholder > div > div:first-of-type {
    justify-content: flex-start;
    max-width: 306px;
    height: 48px;
    margin: 0 5px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    background-color: black;

    cursor: pointer;
}

.welcome_placeholder > div > div:first-of-type > svg,.welcome_placeholder > div > div:nth-of-type(2)> svg {
    margin-left: 10px;
    /*background-color: white;*/
}

.welcome_placeholder > div > div:nth-of-type(2) > img,.welcome_placeholder > div > div:nth-of-type(3) > img {
    margin-left: 10px;
    border-radius: 50%;
    background-color: white;
}

.welcome_placeholder > div > div:nth-of-type(2) > p,
.welcome_placeholder > div > div:nth-of-type(3) > p,
.welcome_placeholder > div > div:first-of-type > p {
    padding-left: 10px;
}


.welcome_placeholder > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
}


.welcome_placeholder > div > div:last-of-type > img {
    padding: 5px;

    cursor: pointer;
}

@media only screen and (max-width: 991px) {
    .welcome_placeholder {
        text-align: center;
    }


    .welcome_placeholder > div {
        display: none;
    }
}

@media only screen and (max-width: 576px) {
    .welcome_placeholder {
        padding: 20px 20px 10px;
    }
}
