.leftPanel_container {
    position: absolute;
    width: clamp(250px, 25%, 400px);

    /* max-width: 400px; */
    /* width: 25%; */

    height: 100%;

    background-color: rgb(245, 245, 245);
    box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 12%);

    transition: transform 0.15s ease-out;
    transform: translateX(-100%);
    z-index: 2;
}

.leftPanel_width {
    transition: transform 0.25s ease-in;
    transform: translateX(0%);
}

.leftPanel_button {
    position: absolute;
    top: calc(50% - 26px);
    left: 100%;
    width: 26px;
    height: 52px;
    color: white;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(0, 0, 0);
    border-radius: 0px 12px 12px 0px;
    background-color: rgb(0, 0, 0, 0.9);
    box-shadow: 1px 1px 4px 1px #888888;

    cursor: pointer;
}

.leftPanel_wrapper {
    position: relative;
    height: 100%;
    overflow-x: hidden;
}

@media only screen and (max-width: 609px) {
    .leftPanel_container {
        width: 100%;

        height: 90%;
        transition: transform 0.25s ease-out;
        transform: translateY(-100%);
    }

    .leftPanel_width {
        transition: transform 0.35s ease-in;
        transform: translateY(0%);
    }

    .leftPanel_button {
        top: 100%;
        /* transform: rotate(90deg); */

        left: calc(50% - 13px);
        transform: translate(0%, -14px) rotate(90deg);
    }


}

/* Scroll bar list place */
.leftPanel_wrapper::-webkit-scrollbar {
    display: none;
}

/* for firefox scroll bar */
.leftPanel_wrapper {
    scrollbar-width: none;
}

.leftPanel_button__hover {
    width: 26px;
    overflow: hidden;
    will-change: width;
    transition: width 0.3s ease-in-out 0.1s;
}

.leftPanel_button.leftPanel_button__hover span {
    display: block;
    font-size: 20px;
    text-align: left;
    will-change: transform;
    transition: transform 0.3s ease-in-out 0.1s;
    transform: translate(-120px, -3px);
}

.leftPanel_button span {
    display: none;
}

.leftPanel_button__hover {
    overflow: hidden;
    text-align: right;
}

.leftPanel_button__hover span {
    position: absolute;
}


@media only screen and (min-width: 610px) {
    @media (hover: hover) {
        .leftPanel_button__hover:hover {
            width: 150px;
        }

        .leftPanel_button__hover:hover span {
            transform: translate(0, -3px);
            transition: transform 0.3s ease-in-out 0.1s;
        }
    }
}

